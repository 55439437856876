export const CARRIER_ID = 'd6bdc565-8d8d-4a3c-9796-af5884a8fbb5'
export const SSO_URL = 'https://sso.rotate.group/'
export const CHECK_WX_API_KEY = '5f834ed10513dd66e5d3850fb5'
export const CARRIER_CALLSIGN = 'VXP'
export const MAPBOX_URI =
  'https://api.mapbox.com/styles/v1/edwinbrowwn/clk93pnzz033t01pa31imfwu7/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZWR3aW5icm93d24iLCJhIjoiY2trcWs0YWZjMDg0bzJ1cXQyODB1NnNydSJ9.tlLwEw7sB5aNaHxQTpqkRw'
export let API_URL =
  process.env.NODE_ENV === 'development'
    ? //'http://localhost:8787/'
    'https://api-v2.rotate.group/'
    : 'https://api-v2.rotate.group/'

export const socialLinks = [
  {
    title: 'Discord',
    link: 'https://discord.gg/9kZuSCxuQy',
  },
]

export const skipAuthPage = false

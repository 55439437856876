/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'

type Props = {
  className?: string
  bgColor?: string
  title?: string
  title2?: string
  title3?: string
  bgUrl?: string
}
const TilesWidget3 = ({
  className,
  bgColor = '#4c12a1',
  title = 'New Haven (KHVN)',
  title2 = '',
  title3 = '',
  bgUrl = '',
}: Props) => {
  return (
    <div
      className={clsx('card h-100 bgi-no-repeat bgi-size-cover', className)}
      style={{
        backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%),url("${bgUrl ? bgUrl : toAbsoluteUrl('/media/misc/new-haven-skyline.jpg')
          }")`,
        backgroundPosition: 'center',
      }}
    >
      {/* begin::Body */}
      <div className='card-body d-flex flex-column justify-content-between'>
        {/* begin::Title */}
        <div className='text-white fw-bold fs-2'>
          <h2 className='fw-bold text-white mb-2'>{title}</h2>
          {title3?.length ? (
            <p
              className='text-white'
              style={{
                fontSize: '1rem',
                opacity: 0.8,
              }}
            >
              ({title3})
            </p>
          ) : (
            ''
          )}
          <a className='text-white' href={title2?.length === 4 ? '/airport/' + title2 : '#'}>
            {title2}
          </a>
        </div>
        {/* end::Title */}

        {/* begin::Link */}
        <a href={`/airport/khvn`} className='text-primary fw-semibold'>
          View KHVN
          <KTSVG
            className='svg-icon-2 svg-icon-primary'
            path='/media/icons/duotune/arrows/arr064.svg'
          />
        </a>
        {/* end::Link */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export { TilesWidget3 }

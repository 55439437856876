import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {
  getDasboardStats,
  getGeneralStats,
  getUserStats,
  getCrewbaseStats,
  getRouteChangelogTime,
  getFeaturedDestinations,
} from '../../../middleware'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  MixedWidget10,
  MixedWidget11,
  MembersTable,
  TotalFlights,
  TilesWidget1,
  TilesWidget4,
  TilesWidget2,
  TilesWidget5,
  TilesWidget3,
  TablesWidget7,
} from '../../../_metronic/partials/widgets'
import {useQuery} from 'react-query'
import moment from 'moment'
const cacheTime = 5 * 60000
const staleTime = 5 * 60000
const useQueryOptions = {cacheTime, staleTime: Infinity}

const DashboardPage = () => {
  const {
    data: dashboardData,
    isLoading: dashboardDataIsLoading,
    refetch: refetchDashboardData,
  } = useQuery('dashboardData', getDasboardStats, useQueryOptions)
  const {data: userData, isLoading: userDataIsLoading} = useQuery(
    'userData',
    getUserStats,
    useQueryOptions
  )
  const {
    data: carrierData,
    isLoading: carrierDataIsLoading,
    refetch: refetchGetGeneralStats,
  } = useQuery('generalStatsData', getGeneralStats, useQueryOptions)
  const {
    data: crewbaseData,
    isLoading: crewbaseDataIsLoading,
    refetch: refetchGetCrewbaseStats,
  } = useQuery('crewbaseData', getCrewbaseStats, useQueryOptions)

  const {
    data: routeChangelogData,
    isLoading: routeChangelogDataIsLoading,
    refetch: refetchRouteChangelogData,
  } = useQuery('routeChangelogData', getRouteChangelogTime, useQueryOptions)

  const {
    data: featuredDestinationData,
    isLoading: featuredDestinationDataIsLoading,
    refetch: refetchFeaturedDestinationData,
  } = useQuery('featuredDestinationData', getFeaturedDestinations, useQueryOptions)

  const loading =
    dashboardDataIsLoading ||
    userDataIsLoading ||
    carrierDataIsLoading ||
    crewbaseDataIsLoading ||
    routeChangelogDataIsLoading ||
    featuredDestinationDataIsLoading

  // Refetch if required data is missing
  useEffect(() => {
    if (!dashboardData && !dashboardDataIsLoading) refetchDashboardData()
    if (!userData && !userDataIsLoading) getUserStats()
    if (!carrierData && !carrierDataIsLoading) refetchGetGeneralStats()
    if (!crewbaseData && !crewbaseDataIsLoading) refetchGetCrewbaseStats()
    if (!routeChangelogData && !routeChangelogDataIsLoading) refetchRouteChangelogData()
    if (!featuredDestinationData && !featuredDestinationDataIsLoading)
      refetchFeaturedDestinationData()
  }, [
    dashboardData,
    userData,
    carrierData,
    crewbaseData,
    routeChangelogData,
    featuredDestinationData,
  ])

  return (
    <>
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-4'>
          <TotalFlights
            className='card-xxl-stretch mb-xl-3'
            chartColor='primary'
            chartHeight='150px'
            loading={loading}
            data={{
              totalFlightsData: dashboardData?.flights_by_90_days,
              airportsData: dashboardData?.airports,
              aircraftData: dashboardData?.aircraft,
              rank: carrierData?.users?.length
                ? carrierData?.users?.find((user: any) => user.public_id === userData?.public_id)
                    ?.rank
                : [],
              rankTotal: carrierData?.users?.length,
            }}
          />
        </div>

        <div className='col-xl-8'>
          <div className='row gx-5 gx-xl-8 mb-5 mb-xl-8'>
            <div className='col-xl-3'>
              <TilesWidget1
                title='Current Location'
                subTitle={userData?.last_location ?? 'N/A'}
                className='card-xl-stretch'
              />
            </div>
            <div className='col-xl-9'>
              <TilesWidget4 className='card-xl-stretch' />
            </div>
          </div>

          <div className='row gx-5 gx-xl-8 mb-5 mb-xl-8'>
            <div className='col-xl-6'>
              <TilesWidget2
                data={routeChangelogData}
                loading={loading}
                className='card-xl-stretch mb-5 mb-xl-8'
              />
              <div className='row gx-5 gx-xl-8'>
                <div className='col-xl-6'>
                  <TilesWidget5
                    className='card-xxl-stretch bg-primary'
                    svgIcon='/media/icons/duotune/aviation/outline/Landing.svg'
                    titleClass='text-white'
                    descriptionClass='text-white'
                    iconClass='svg-icon-white'
                    title={
                      dashboardData?.user?.avg_landing_rate
                        ? String(Math.floor(dashboardData?.user?.avg_landing_rate))
                        : 'N/A'
                    }
                    description='Avg Landing Rate'
                  />
                </div>
                <div className='col-xl-6'>
                  <TilesWidget5
                    className='card-xxl-stretch bg-body'
                    svgIcon='/media/icons/duotune/aviation/outline/RoundTrip.svg'
                    titleClass='text-dark'
                    descriptionClass='text-muted'
                    iconClass='svg-icon-white'
                    title={
                      dashboardData?.user?.total_flight_time
                        ? String(Math.floor(dashboardData?.user?.total_flight_time)) + ' hrs'
                        : '0 hrs'
                    }
                    description='Total Time'
                  />
                </div>
              </div>
            </div>
            <div className='col-xl-6'>
              <TilesWidget3
                className='card-xl-stretch mb-5 mb-xl-8'
                title2={featuredDestinationData?.icao}
                title3={
                  !!featuredDestinationData?.start_date
                    ? `${moment(featuredDestinationData?.start_date).format(
                        'MMM DD, YYYY'
                      )} - ${moment(featuredDestinationData?.end_date).format('MMM DD, YYYY')}`
                    : ''
                }
                bgUrl={featuredDestinationData?.image_url}
              />
            </div>
          </div>
        </div>
      </div>

      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        <div className='col-xl-8'>
          <MembersTable
            loading={loading}
            className='card-xxl-stretch mb-5 mb-xl-8'
          />
        </div>

        <div className='col-xxl-4'>
          <MixedWidget10
            className='card-xxl-stretch-50 mb-5 mb-xl-8'
            chartColor='primary'
            chartHeight='150px'
            data={dashboardData?.user?.points && dashboardData?.user?.points}
            loading={loading}
          />
          <MixedWidget11
            className='card-xxl-stretch-50 mb-5 mb-xl-8'
            chartColor='primary'
            chartHeight='175px'
            data={crewbaseData}
            loading={loading}
          />
        </div>
      </div>
      {/* end::Row */}

      {/* <div className='row gy-5 g-xl-8'>
        <div className='col-xl-12'>
          <WidgetBot
            height={500}
            width={'100%'}
            server='611978357369995265'
            channel='975096215949697064'
          />
        </div>
      </div> */}

      {/* begin::Row */}
      {/* <div className='row gy-5 g-xl-8'>
        <div className='col-xl-4'>
          <ListsWidget2 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ListsWidget6 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
        </div>
      </div> */}
      {/* end::Row */}
      {/* <div className='col-xxl-4'>
          <ListsWidget5 className='card-xxl-stretch' />
        </div> */}
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
